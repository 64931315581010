import { withScope } from '@sentry/solidstart';
import { decode } from 'decode-formdata';
import deepmerge from 'deepmerge';
import type { AnyVariables, DocumentInput } from '@urql/core';

class FormFilterWarning extends Error {}

export function formDataToObject<Data = unknown, Variables extends AnyVariables = AnyVariables>(
	query: DocumentInput<Data, Variables>,
	data: FormData,
	transform?: (data: FormData) => Partial<Variables>,
): Variables {
	// Filter out inappropriate values
	const possibleValues: Array<string> =
		// @ts-expect-error
		query.definitions[0].variableDefinitions?.map(
			// @ts-expect-error
			({ variable }) => variable.name.value,
		) ?? [];

	const decoded = decode(data);
	const out: Partial<Variables> = {} as Partial<Variables>;
	for (const [key, val] of Object.entries(decoded)) {
		// Anything that starts with '__' can be considered internal/implementation and should automatically be filtered
		if (key.startsWith('__')) {
			continue;
		}
		if (!possibleValues.includes(key) && !key.includes('password')) {
			withScope((scope) => {
				scope.setLevel('warning');
				scope.setExtra('inputName', key);
				scope.setExtra('graphQLQuery', query);
				scope.captureException(new FormFilterWarning('Filtered unknown input element from query'));
			});
			continue;
		}

		if (!(key in out)) {
			if (val instanceof File) {
				continue;
			}
			out[key as keyof Variables] = (
				!/password/i.test(key) && typeof val === 'string' ? val.trim() : val
			) as Variables[keyof Variables];
		}
	}

	return deepmerge(out, transform ? transform(data) : ({} as Partial<Variables>)) as Variables;
}

export function objectToFormData(data: Record<string, unknown>, prefix?: string, form = new FormData()) {
	for (const [key, val] of Object.entries(data)) {
		if (typeof val === 'string' || typeof val === 'number') {
			form.set(`${prefix ? `${prefix}.` : ''}${key}`, val.toString());
		} else if (typeof val === 'object' && val) {
			objectToFormData(val as Record<string, unknown>, key, form);
		}
	}
	return form;
}
