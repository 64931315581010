import { action, redirect } from '@solidjs/router';
import { useToast } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import { getApiClient } from './get-api-client';
import { formDataToObject } from './form-data';
import { revalidate } from './cache';
import type { CacheKey } from './cache';
import type { AnalyticsEvent, Track } from '@troon/analytics';
import type { Action } from '@solidjs/router';
import type { AnyVariables, DocumentInput, OperationContext, OperationResult } from '@urql/core';

type TrackEvent<Data = unknown, Variables extends AnyVariables = AnyVariables> = {
	/**
	 * Request event name to track. Request/Success/Failure will automatically be appended to the end of the tracking at the appropriate time.
	 */
	event: AnalyticsEvent;
	/**
	 * Base data to provide to the tracked event.
	 */
	data?: Record<string, string>;
	/**
	 * Transform (and merge) information from the action's FormData onto the event's tracked data.
	 */
	transform?: (
		data: FormData,
		res?: OperationResult<Data, Variables>['data'],
	) => Record<string, string | number | undefined | null | Array<string | number>>;
};

type DeepPartial<T> = T extends object
	? {
			[P in keyof T]?: DeepPartial<T[P]>;
		}
	: T;

type MutationOptions<Data = unknown, Variables extends AnyVariables = AnyVariables> = {
	/**
	 * Array of cache keys to revalidate after the mutation successfully posts and returns from the API server.
	 * Explicitly send `undefined` to forcibly revalidate _all_ cached data. Sending an empty array is equivalent
	 * to not including the key (revalidate nothing).
	 */
	revalidates?: Array<CacheKey>;
	/**
	 * Shows a positive toast message after the mutation successfully posts and returns from the API server.
	 */
	toast?: string | ((data: OperationResult<Data, Variables>['data']) => string);
	/**
	 * Transform the FormData passed to the action to the proper shape for the mutation.
	 */
	transform?: (data: FormData) => DeepPartial<Variables>;
	/**
	 * Track analytics events
	 */
	track?: TrackEvent<Data, Variables> | Array<TrackEvent<Data, Variables>>;
	/**
	 * Retry the mutation in the event of an error or conditional logic
	 */
	retry?: {
		retryIf?: (result: OperationResult<Data, Variables>) => boolean;
		maxAttempts?: number;
		delay?: number;
	};
	/**
	 * Call a method on mutation success. May not be combined with the `redirect` path option.
	 */
	onSuccess?: ((result: OperationResult<Data, Variables>['data']) => PromiseLike<void>) | (() => void);
	/**
	 * Redirect to this pathname after mutation success.
	 */
	redirect?: string | ((result: OperationResult<Data, Variables>['data']) => string);
	redirectOptions?: Omit<ResponseInit, 'body'> & { state?: Record<string, unknown> };
};

export function useMutation<Data = unknown, Variables extends AnyVariables = AnyVariables>(
	mutation: ReturnType<typeof mutationAction<Data, Variables>>,
) {
	const track = useTrackEvent();
	return mutation({ track });
}
/**
 * Simplify common handling for mutations.
 */

export function mutationAction<Data = unknown, Variables extends AnyVariables = AnyVariables>(
	/**
	 * The mutation to run
	 */
	mutation: DocumentInput<Data, Variables>,
	opts?: MutationOptions<Data, Variables>,
	context?: Partial<OperationContext> | undefined,
): (req: { track: Track }) => Action<[data: FormData], OperationResult<Data, Variables | Record<string, string>>> {
	const addToast = useToast();

	return (req) =>
		action(
			async (data: FormData) => {
				const tracks = Array.isArray(opts?.track) ? opts.track : opts?.track ? [opts.track] : [];
				const trackData = tracks.map((track) => ({
					...track.data,
					...(track.transform ? track.transform(data) : undefined),
				}));

				const submitData = formDataToObject(mutation, data, opts?.transform) as Variables;

				let res: OperationResult<Data, Variables>;

				const maxAttempts = import.meta.env.NODE_ENV === 'test' ? 1 : !opts?.retry ? 1 : (opts.retry?.maxAttempts ?? 3);
				const retryIf = opts?.retry?.retryIf ?? (() => false);
				for (let i = 0; i < maxAttempts; i += 1) {
					res = await getApiClient().mutation(mutation, submitData, context);
					if (!retryIf(res)) {
						break;
					}
					await new Promise<void>((resolve) => {
						setTimeout(() => {
							resolve();
						}, opts?.retry?.delay ?? 500);
					});
				}

				if (res! && !res.error && res.data) {
					tracks.forEach((track, i) => {
						req.track(track.event, {
							...trackData[i],
							...(track.transform ? track.transform(data, res.data) : undefined),
						});
					});

					const revalidateKeys = 'revalidates' in (opts ?? {}) ? opts?.revalidates : [];
					await revalidate(revalidateKeys, true);

					if (opts?.toast) {
						addToast(typeof opts.toast === 'function' ? opts.toast(res.data) : opts.toast, {
							variant: 'positive',
							timeout: 5000,
						});
					}
					if (opts?.onSuccess) {
						await opts.onSuccess(res.data);
					}
					if (opts?.redirect) {
						return redirect(
							typeof opts.redirect === 'function' ? opts.redirect(res.data) : opts.redirect,
							opts.redirectOptions ?? {},
						);
					}
				}
				return res!;
			},
			// @ts-expect-error
			mutation.definitions.reduce((memo, def) => `${memo}${def.name.value}`, ''),
		);
}
